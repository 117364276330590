import React from 'react';
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import './pagination.css';  // Your custom styles here

const CustomPagination = ({ rowsPerPage, rowCount, onChangePage, currentPage }) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      onChangePage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onChangePage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const range = 2; // Number of pages to show around the current page

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages) {
        // Always show first and last page
        pageNumbers.push(
          <button
            key={i}
            onClick={() => onChangePage(i)}
            className={`pagination-number ${i === currentPage ? 'active' : ''}`}
          >
            {i}
          </button>
        );
      } else if (i >= currentPage - range && i <= currentPage + range) {
        // Show a range of pages around the current page
        pageNumbers.push(
          <button
            key={i}
            onClick={() => onChangePage(i)}
            className={`pagination-number ${i === currentPage ? 'active' : ''}`}
          >
            {i}
          </button>
        );
      } else if (i === currentPage - range - 1 || i === currentPage + range + 1) {
        // Add ellipses when there's a gap between pages
        pageNumbers.push(
          <span key={i} className="pagination-ellipsis">...</span>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className="custom-pagination mt-6 p-4">
        <button
            onClick={handlePrevious}
            className="items-center justify-center inline-flex py-1.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 previous"
            disabled={currentPage === 1}
        >
            <FiArrowLeft className="mr-2" /> Previous
        </button>
        <div className="page-numbers">
            {renderPageNumbers()}
        </div>
        <button
            onClick={handleNext}
            className="inline-flex items-center justify-center py-1.5 px-5 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-[#3F3DA5] hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right next"
            disabled={currentPage === totalPages}
        >
            Next <FiArrowRight className="ml-2" />
        </button>
    </div>
  );
};

export default CustomPagination;
