import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { Header, SkeletonTable } from "../../components";
//import { useStateContext } from "../../contexts/ContextProvider";
import { userRequest } from "../../utils/requestMethods";
import { formatCurrency } from "../../utils/currencyUtils";
import { MdSearch } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
    },
  },
};

const DiscountReconciliation = () => {
  //const { user } = useStateContext();
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');

  // Fetch Discounts
  const fetchDiscounts = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`/transactions/school/marketing-discounts`);
      setDiscounts(data.discounts);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDiscounts();
  }, [fetchDiscounts]);

  // DataTable Columns
  const columns = [
    {
      name: "School ID",
      selector: (row) => row.school_id || "N/A",
    },
    {
      name: "School Name",
      selector: (row) => row.school_name || "N/A",
    },
    {
      name: "Total Marketing Discount",
      selector: (row) => formatCurrency(row.total_marketing_discount),
    },
    {
      name: "Total Settled Discount",
      selector: (row) => <span className="text-green-500">{formatCurrency(row.total_settled_discount)}</span>,
    },
    {
      name: "Total Unsettled Discount",
      selector: (row) => <span className="text-red-500">{formatCurrency(row.total_unsettled_discount)}</span>,
    },
    {
      name: "Actions",
      cell: (row) => (
        <Link to={{ pathname: `/discounts/details/${row.school_id}`, state: { school: row } }}>
          <button className="bg-indigo-300 text-black text-md shadow rounded-full p-2 hover:bg-indigo-400">
            View Trasactions
          </button>
        </Link>
      ),
      ignoreRowClick: true,
      //button: true,
    },
  ];

  const filteredData = discounts?.filter((item) =>
    (item.school_name?.toLowerCase().includes(filterText.toLowerCase()) || '') 
  );

  return (
    <div className="m-3 mt-14 md:mt-4 p-4 md:p-7 bg-white rounded-2xl">
      <Header category="Discounts" title="Parent Discount" />
      <div className="py-4 overflow-scroll md:overflow-hidden">
        <div className='flex flex-wrap items-center justify-between mb-6'>
          <form className="max-w-lg">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center pl-4 pointer-events-none">
                <MdSearch />
              </div>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2.5"
                placeholder="Search"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="border rounded-3xl">
          <DataTable
            data={filteredData}
            columns={columns}
            pagination
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<SkeletonTable rows={5} columns={6} />}
            highlightOnHover
            striped
          />
        </div>
      </div>
    </div>
  );
};

export default DiscountReconciliation;
