import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { userRequest } from "../../utils/requestMethods";
import { CustomPagination, NoDataComponent, SkeletonTable, StatusBadge } from "../../components";
import logo from "../../data/logo/logo.png";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
      fontWeight: "bold",
    },
  },
};

const StudentResult = ({ school, studentId, classId }) => {
  const schoolTerm = school?.settings?.current_term;
  const schoolSession = school?.settings?.current_session;
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSubjects = async () => {
    setLoading(true);
    try {
      const { data } = await userRequest.get(`result/${school?._id}/${studentId}?class_id=${classId}&term=${schoolTerm}&session=${schoolSession}`);
      console.log(data);
      setSubjects(data.results || []);
    } catch (error) {
      console.error("Error fetching subjects:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, [school?._id]);

  const columns = [
    { name: "Subject Name", selector: (row) => row.subject.name },
    {
      name: `CA Score (${school?.settings?.assessment?.ca?.max_score})`,
      selector: (row) =>
        Array.isArray(row?.cas)
          ? row.cas.reduce((sum, item) => sum + item.score, 0)
          : 0,
    },
    {
      name: `Exam Score (${school?.settings?.assessment?.exam?.max_score})`,
      selector: (row) => row.exam,
    },
    { name: "Total", selector: (row) => row.total },
    { name: "Percentage (%)", selector: (row) => row.total },
    {
      name: "Status",
      selector: () => <StatusBadge status="active" label="Uploaded" />,
    },
  ];

  const exportPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
  
    // Header
    doc.addImage(logo, "PNG", 10, 10, 30, 20); // Adjust logo size and position
    doc.setFontSize(14);
    doc.text(school?.school_name || "School Name", pageWidth / 2, 15, { align: "center" });
    doc.setFontSize(10);
    doc.text(
      school?.address?.main?.address_line || "School Address",
      pageWidth / 2,
      20,
      { align: "center" }
    );
    doc.text(`Term: ${schoolTerm} | Session: ${schoolSession}`, pageWidth / 2, 25, { align: "center" });
    doc.text(`Student ID: ${studentId}`, pageWidth / 2, 30, { align: "center" });
  
    // Student Info
    doc.setFontSize(11);
    doc.text("Student Information", 10, 40);
    doc.setFontSize(10);
    doc.text(`Name: Ifunanya Kelemade`, 10, 45);
    doc.text(`Gender: Female`, 10, 50);
    doc.text(`Class: Primary 1`, 10, 55);
    doc.text(`Total Days: 102`, 10, 60);
    doc.text(`Days Present: 85`, 10, 65);
  
    // Subjects Table
    const tableData = subjects.map((subject) => [
      subject.subject.name,
      Array.isArray(subject.cas)
        ? subject.cas.reduce((sum, item) => sum + item.score, 0)
        : 0,
      subject.exam,
      subject.total,
    ]);
    doc.autoTable({
      head: [["Subject", "CA Score", "Exam Score", "Total"]],
      body: tableData,
      startY: 70,
    });
  
    // Skills Section
    doc.text("Affective Skills", 10, doc.lastAutoTable.finalY + 10);
    const skills = [
      ["Punctuality", 4],
      ["Politeness", 3],
      ["Neatness", 4],
      ["Honesty", 5],
      ["Cooperation", 4],
    ];
    doc.autoTable({
      body: skills,
      theme: "plain",
      startY: doc.lastAutoTable.finalY + 15,
    });
  
    // Remarks and Grading
    doc.text("Class Teacher's Remark: A very promising child", 10, doc.lastAutoTable.finalY + 30);
    doc.text("School Head's Remark: Brilliant performance", 10, doc.lastAutoTable.finalY + 35);
    doc.text("Grading System", pageWidth - 60, doc.lastAutoTable.finalY + 30);
    const grading = [
      ["A", "80-100"],
      ["B", "65-79"],
      ["C", "50-64"],
      ["D", "45-49"],
      ["F", "0-44"],
    ];
    doc.autoTable({
      body: grading,
      theme: "plain",
      startY: doc.lastAutoTable.finalY + 40,
      margin: { left: pageWidth - 60 },
    });
  
    // Save the PDF
    doc.save(`Student_Report_${studentId}.pdf`);
  };
  

  return (
    <div>      
      <div className="flex flex-wrap justify-between items-center mb-4">
        <h1 className="text-lg font-semibold w-full md:w-auto mb-2 md:mb-0" style={{ maxWidth: "20em" }}>
          Result
        </h1>
        <div className="flex flex-wrap justify-center md:justify-end w-full md:w-auto gap-2">
          {/* Search Input */}
          <button onClick={exportPDF} className="bg-indigo-800 hover:bg-indigo-700 text-white px-4 py-2 rounded-full mb-4">
            Export to PDF
          </button>
        </div>
      </div>
      <div className="border rounded-3xl">
        <DataTable
          data={subjects}
          columns={columns}
          getRowId={(row) => row._id}
          noDataComponent={<NoDataComponent />}
          pagination
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={<SkeletonTable rows={5} columns={6} />}
          highlightOnHover
          striped
        />
      </div>
    </div>
  );
};

export default StudentResult;