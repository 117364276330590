import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { userRequest } from '../../utils/requestMethods';
import { Header, ButtonLoading } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';

const UpdateClass = ({ history }) => {
  const location = useLocation();
  const childId = location.pathname.split('/')[3];
  //const school = location.state?.school;
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  //const [refresh, setRefresh] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchChild = async () => {
      try {
        setLoading(true);
        const { data } = await userRequest.get(`children/${childId}`);
        console.log(data);
        setFirstname(data.firstname);
        setLastname(data.lastname);
        setGender(data.gender);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchChild();
  }, [childId]);

  const goBack = () => {
    // Go back to the previous page
    history.goBack();
  };
  
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      await userRequest.put(`/children/${childId}`,
        { 
          firstname, 
          lastname,
          gender,
        });
      //console.log(data);
      setSuccess("Student details updated successfully");
      setTimeout(() => {
        setSuccess("");
        //history.push("/classes");
      }, 5000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
    setLoading(false);
  };

  return (
    <div className="m-2 md:m-10 mt-24  p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <button onClick={goBack} className="inline-flex justify-center py-2 px-6 mb-2 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><i class="fad fa-arrow-left"></i></button>
      <Header category="Students" title="Update Student Record" />
      <div className="py-2 items-right">
        <div className="mt-2 max-w-full">
          <form onSubmit={submitHandler}>
            <div className="grid grid-cols-1 gap-6">
              {error &&
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                  <i className="fal fa-lock pr-10"></i>
                  <div>
                    {error}
                  </div>
                </div>
              }
              {success &&
                <div className="alert alert-success d-flex align-items-center" role="alert">
                  <i className="fal fa-lock pr-10"></i>
                  <div>
                    {success}
                  </div>
                </div>
              }
                <div>
                    <label htmlFor="firstName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
                    <input type="text" id="firstName" onChange={(e) => setFirstname(e.target.value)} value={firstname} placeholder="First Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                </div>
                <div>
                    <label htmlFor="lastName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
                    <input type="text" id="lastName" onChange={(e) => setLastname(e.target.value)} value={lastname} placeholder="Last Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                </div>
                <div>
                    <label htmlFor="gender" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Gender</label>
                    <select id="gender" onChange={(e) => setGender(e.target.value)} value={gender} className="block w-full mt-1 rounded-md bg-gray-100 border-gray-300 focus:border-gray-500 focus:bg-white focus:ring-0">
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
              <div className="px-4 py-3 text-right sm:px-6"> 
                {loading ?
                  (
                    <ButtonLoading styles="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-indigo-600" />
                  ) : (
                    <button type="submit" className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Update Student</button>
                  )
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdateClass;
