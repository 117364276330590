import React from 'react'
import DataTable from 'react-data-table-component'
import CustomPagination from '../CustomPagination/CustomPagination';
import * as Icons from "../../data/teacher";
import SkeletonTable from '../common/SkeletonTable';
import NoDataComponent from '../common/NoDataComponent';

const customStyles = {
    headRow: {
        style: {
            backgroundColor: 'rgb(248, 250, 252)',
            fontWeight: "bold",
        },
    },
};

const StudentAttendance = ({ studentAttendance, loading, onAttendanceView }) => {
    const data = [
        { id: 1, Week: '1', }, { id: 2, Week: '2', }, { id: 3, Week: '3', }, { id: 4, Week: '4', },
        { id: 5, Week: '5', }, { id: 6, Week: '6', }, { id: 7, Week: '7', },
    ];

    const columns = [
        {
            name: "Week",
            selector: row => row.week,
        },
        {
            name: "Monday",
            cell: row => {
                const monday = row.days.find(day => day.day_of_week === 1); // 1 for Monday
                if (!monday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return monday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Tuesday",
            cell: row => {
                const tuesday = row.days.find(day => day.day_of_week === 2); // 2 for Tuesday
                if (!tuesday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return tuesday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Wednesday",
            cell: row => {
                const wednesday = row.days.find(day => day.day_of_week === 3); // 3 for Wednesday
                if (!wednesday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return wednesday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Thursday",
            cell: row => {
                const thursday = row.days.find(day => day.day_of_week === 4); // 4 for Thursday
                if (!thursday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return thursday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Friday",
            cell: row => {
                const friday = row.days.find(day => day.day_of_week === 5); // 5 for Friday
                if (!friday) {
                    return <img src={Icons.studentPending} alt="Pending Icon" />;
                }
                return friday.status === 1 ? (
                    <img src={Icons.studentPresent} alt="Present Icon" />
                ) : (
                    <img src={Icons.studentAbsent} alt="Absent Icon" />
                );
            },
        },
        {
            name: "Attendance Rate",
            cell: row => {
                const totalDays = row.days.length;
                const daysPresent = row.days.filter(day => day.status === 1).length;
                const rate = Math.round((daysPresent / totalDays) * 100);
                return <p className="text-[#3DCE3D]">{rate}%</p>;
            },
        },
    ];

  return (
    <div>
        <p className='text-2xl font-semibold mb-3'>Attendance</p>
        <div className="border rounded-3xl">
            <DataTable
                columns={columns}
                data={studentAttendance}
                noDataComponent = {<NoDataComponent/>}
                pagination
                paginationComponent={CustomPagination}
                paginationRowsPerPageOptions={[10, 20, 30, 50]}
                customStyles={customStyles}
                progressPending={loading}
                progressComponent={<SkeletonTable rows={5} columns={6} />}
                highlightOnHover
                striped
            />
        </div>
    </div>
  )
}

export default StudentAttendance