import React, { useState, useEffect, useCallback } from "react";
import DataTable from "react-data-table-component";
import { BackButton, Header, SkeletonTable } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import { userRequest } from "../../utils/requestMethods";
import { formatCurrency } from "../../utils/currencyUtils";
import { MdSearch } from "react-icons/md";
import { useLocation } from "react-router-dom";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
    },
  },
};

const DiscountTransactions = ({ history }) => {
  const location = useLocation();
  const schoolId = location.pathname.split('/')[3];
  const school = location.state?.school;
  const { user } = useStateContext();
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reimburseLoading, setReimburseLoading] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  // Fetch Discounts
  const fetchTransactions = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`transactions/${user._id}/${schoolId}?marketing_discount=true&type=school_fee&limit=1000&sort_by=date&order=des`);
      console.log(data);
      setTransactions(data.transactions);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  // Handle Individual Reimbursement
  const reimburseIndividual = async (transactionId) => {
    try {
      setReimburseLoading((prev) => ({ ...prev, [transactionId]: true }));
      const response = await userRequest.post(
        `/transactions/school/${school.school_id}/marketing-discounts/settle`,
        { transactionIds: [transactionId] }
      );
      setSuccess(`Successfully reimbursed transaction ID: ${transactionId}`);
      setError("");
      setTimeout(() => {
        setSuccess("");
      }, 2000);
      fetchTransactions();
    } catch (err) {
      console.error(err);
      setError("Failed to reimburse the transaction.");
      setSuccess("");
      setTimeout(() => {
        setError("");
      }, 2000);
    } finally {
      setReimburseLoading((prev) => ({ ...prev, [transactionId]: false }));
    }
  };

  // Handle Multi-Select Reimbursement
  const reimburseSelected = async () => {
    if (selectedRows.length === 0) {
      setError("No transactions selected for reimbursement.");
      setSuccess("");
      return;
    }
    try {
      setReimburseLoading((prev) => ({ ...prev, selected: true }));
      const transactionIds = selectedRows.map((row) => row._id);
      await userRequest.post(
        `/transactions/school/${school.school_id}/marketing-discounts/settle`,
        { transactionIds }
      );
      setSuccess("Successfully reimbursed selected transactions.");
      setError("");
      setTimeout(() => {
        setSuccess("");
      }, 2000);
      setSelectedRows([]);
      fetchTransactions();
    } catch (err) {
      console.error(err);
      setError("Failed to reimburse selected transactions.");
      setSuccess("");
      setTimeout(() => {
        setError("");
      }, 2000);
    } finally {
      setReimburseLoading((prev) => ({ ...prev, selected: false }));
    }
  };

  const columns = [
    {
      name: "Who Paid?",
      cell: row => {
        const parentName = row.metadata?.parent_name;
        return parentName && parentName !== "undefined undefined" ? parentName : 'N/A';
      },
    },
    {
      name: "Student Name",
      cell: row => row.child ? `${row.child.firstname} ${row.child.lastname}` : 'N/A',
    },
    {
      name: "Student Class",
      cell: row => row.metadata?.class?.class_name || 'N/A',
    },
    {
      name: "Amount Paid",
      cell: row => formatCurrency(row.amount),
    },
    {
      name: "Total Fees",
      cell: row => formatCurrency(row.total_amount),
    },
    {
      name: "Total Paid",
      cell: row => formatCurrency(row.amount_after),
    },
    {
      name: "Balance",
      cell: row => formatCurrency(Number(row.total_amount) - Number(row.amount_after)),
    },
    {
      name: "Paid At",
      cell: row => new Date(row.updatedAt).toLocaleString('en-NG', { hour12: true }),
      sortable: true,
    },
    {
      name: "Marketing Discount",
      cell: row => formatCurrency(row.marketing_discount.amount)+` (${row.marketing_discount.percentage}%)`,
    },
    {
      name: "KYC Status",
      selector: (row) => <button style={row.marketing_discount.is_settled ? { background: 'rgb(139, 231, 139)' } : { background: 'rgb(227, 160, 8)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-sm">{row.marketing_discount.is_settled ? ("Settled") : ("Pending")}</button>,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          onClick={() => reimburseIndividual(row._id)}
          className={`bg-indigo-300 text-black text-md shadow rounded-full p-2 hover:bg-indigo-400 ${reimburseLoading[row._id] ? "opacity-50 cursor-not-allowed" : ""}`}
          disabled={reimburseLoading[row._id]}
        >
          {reimburseLoading[row._id] ? "Processing..." : "Reimburse"}
        </button>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ]; 

  const filteredData = transactions?.filter((item) =>
    (item.metadata?.parent_name?.toLowerCase().includes(filterText.toLowerCase()) || '') 
  );

  return (
    <div className="m-3 mt-14 md:mt-4 p-4 md:p-7 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <BackButton history={history} />
      <Header category="Discounts" title={`${school.school_name}`} />
      <div className="py-4 overflow-scroll md:overflow-hidden">
        {success && (
          <div className="alert alert-success" role="alert">
            {success}
          </div>
        )}
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        
        <div className='flex flex-wrap items-center justify-between mb-6'>
          <button onClick={reimburseSelected} className="bg-green-600 text-white text-md rounded-full px-4 py-2 hover:bg-green-700" disabled={reimburseLoading.selected}>
            {reimburseLoading.selected ? "Processing..." : "Reimburse Selected"}
          </button>
          <form className="max-w-lg">
            <div className="relative">
              <div className="absolute inset-y-0 start-0 flex items-center pl-4 pointer-events-none">
                <MdSearch />
              </div>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full pl-8 p-2.5"
                placeholder="Search"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="border rounded-3xl">
          <DataTable
            data={filteredData}
            columns={columns}
            selectableRows
            onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
            pagination
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<SkeletonTable rows={5} columns={6} />}
            highlightOnHover
            striped
          />
        </div>
      </div>
    </div>
  );
};

export default DiscountTransactions;
