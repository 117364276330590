import React, { useState } from 'react'
import ButtonLoading from '../ButtonLoading'
import { userRequest } from '../../utils/requestMethods';

const AddStudentModal = ({ closeModal, schoolCode, schoolId, classId, goback, onStudentCreated }) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!firstname || !lastname || !gender) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }
    try {
      await userRequest.post("/children/create",
        { 
          school_code:schoolCode,
          class_id: classId, 
          firstname,
          lastname,
          gender,
        });
      //console.log(data);
      onStudentCreated();
      closeModal();
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setFirstname("");
        setLastname("");
        setGender("");
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  return(
    <div className="mt-4">
      <p className="text-sm text-gray-500 mt-2">
        Enter student academic details
      </p>
      <div className="mt-3">
        {error &&
        <div className="p-4 mb-4 text-sm text-white rounded-lg bg-red-500" role="alert">
            <span className="font-medium">Error!</span> {error}
        </div>}
        {success &&
        <div className="p-4 mb-4 text-sm text-white rounded-lg bg-green-500" role="alert">
          <span className="font-medium">Success!</span> New Student Added
        </div>}
        <form onSubmit={submitHandler} class="space-y-4">
          <div>
            <label htmlFor="firstName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
            <input type="text" id="firstName" onChange={(e) => setFirstname(e.target.value)} value={firstname} placeholder="First Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div>
          <div>
            <label htmlFor="lastName" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
            <input type="text" id="lastName" onChange={(e) => setLastname(e.target.value)} value={lastname} placeholder="Last Name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
          </div>
          <div>
          <label htmlFor="gender" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Gender</label>
          <select id="gender" onChange={(e) => setGender(e.target.value)} value={gender} className="block w-full mt-1 rounded-md bg-gray-100 border-gray-300 focus:border-gray-500 focus:bg-white focus:ring-0">
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
          </div>
          <div className="flex justify-between items-center">
            <button type="button" onClick={goback} className="text-gray-600 bg-white border border-gray-300 font-semibold rounded-full text-sm px-5 py-2 mr-3">&larr; Back</button>
            <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center">
                {loading ? 'Adding...' : 'Add Student'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default AddStudentModal