import React from "react";

const StatusBadge = ({ status, label }) => {
  const statusStyles = {
    active: {
      color: "text-green-500",
      dot: "bg-green-500",
      label: label || "Active",
    },
    inactive: {
      color: "text-red-500",
      dot: "bg-red-500",
      label: label || "Inactive",
    },
  };

  const currentStatus = statusStyles[status.toLowerCase()] || {};

  return (
    <div className="flex items-center space-x-2">
      {/* Status Dot */}
      <span
        className={`w-2.5 h-2.5 rounded-full ${currentStatus.dot}`}
      ></span>
      {/* Status Label */}
      <span className={`text-sm font-medium ${currentStatus.color}`}>
        {currentStatus.label}
      </span>
    </div>
  );
};

export default StatusBadge;
