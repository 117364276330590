import React, { useEffect, useState } from "react";
import { BackButton, StudentAttendance, StudentResult } from "../../components";
import { StudentAddons } from "../../components";
import { useLocation } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import { userRequest } from "../../utils/requestMethods";


const StudentProfile = ({ history }) => {
    const [board, setBoard] = useState("attendance");
    const location = useLocation();
    const studentId = location.pathname.split('/')[4];
    const classId = location.pathname.split('/')[5];
    const student = location.state?.student;
    const school = location.state?.school;
    console.log(school);
    const { user } = useStateContext();
    const schoolTerm = school?.settings?.current_term;
    const schoolSession = school?.settings?.current_session;
    const [studentAttendance, setStudentAttendance] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchAttendance = async () => {
        setLoading(true);
        try {
            const { data } = await userRequest.get(`/attendance/student/${studentId}?class_id=${classId}&school_id=${school?._id}&term=${schoolTerm}&session=${schoolSession}&limit=50`);
            console.log(data);
            setStudentAttendance(data?.data || []);
        } catch (error) {
            console.error("Error fetching attendance:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true; // Prevent state update after unmount
        const fetchData = async () => {
            if (isMounted) {
                await fetchAttendance();
            }
        };
        fetchData();

        return () => {
            isMounted = false; // Cleanup function
        };
    }, [school?._id, user?._id]);

    // Calculate Days Present
    const daysPresent = studentAttendance.reduce((total, record) => {
        return total + record.days.filter(day => day.status === 1).length;
    }, 0);

    // Calculate Days Absent
    const daysAbsent = studentAttendance.reduce((total, record) => {
        return total + record.days.filter(day => day.status === 2).length;
    }, 0);

    // Calculate Total Days
    const totalDays = studentAttendance.reduce(
        (total, record) => total + record.days.length,
        0
    );

    // Calculate Attendance Rate
    const attendanceRate = totalDays > 0 ? Math.round((daysPresent / totalDays) * 100) : 0;

    return (
        <div className="w-full flex items-center justify-center py-14 md:py-8 relative">

            <div className="bg-transparent w-full pb-16 px-9 py-8 sm:py-0">
                <div className="bg-white rounded-2xl p-6">
                    <BackButton history={history} />
                    <div className="w-14 h-14 rounded-full bg-blue-500 mb-4 mt-4 border-[3px] border-[#D8846E]"></div>

                    <div className="flex flex-wrap items-center justify-between">
                        <div className="w-full sm:w-max mb-4 sm:mb-0">
                            <p className="text-2xl font-semibold mb-2">{student}</p>
                            <p className="text-sm text-[#606060]">{schoolTerm} Term - {schoolSession} Attendance</p>
                        </div>
                        <button className="hover:bg-indigo-900 rounded-full text-sm text-white px-3 py-2 bg-[#3F3DA5]">
                            Export Attendance
                        </button>
                    </div>

                    <div className="border-t border-t-[#E7E7E7] mt-6 mb-6"></div>

                    <div className='bg-[#EEEEFC] rounded-xl flex items-center justify-between'>
                        <div className='p-4'>
                            <p className='text-sm text-[#606060] mb-2'>Days Present</p>
                            <p className='text-3xl font-semibold'>{daysPresent}</p>
                        </div>
                        <div className='p-4'>
                            <p className='text-sm text-[#606060] mb-2'>Days Absent</p>
                            <p className='text-3xl font-semibold'>{daysAbsent}</p>
                        </div>
                        <div className='p-4'>
                            <p className='text-sm text-[#606060] mb-2'>Attendance Rate</p>
                            <p className='text-3xl font-semibold'>
                                {attendanceRate}%
                            </p>
                        </div>
                    </div>
                </div>


                <div className="bg-white rounded-2xl mt-6 px-6 py-7">
                    <div className="flex flex-wrap items-center my-3">
                        <button onClick={() => {
                            setBoard("attendance")
                        }} className={board === "attendance" ? "sm:w-max w-full bg-[#C5C5E8] hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between"
                            : "sm:w-max w-full bg-white hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between"
                        }>
                            Attendance
                        </button>

                        {/*<button onClick={() => { setBoard("fees") }} className={board === "fees" ? "sm:w-max w-full bg-[#C5C5E8] hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between"
                            : "sm:w-max w-full bg-white hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between"
                        }>
                            Extra Fees
                        </button>*/}

                        <button onClick={() => { setBoard("result") }} className={board === "result" ? "sm:w-max w-full bg-[#C5C5E8] hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between"
                            : "sm:w-max w-full bg-white hover:bg-indigo-300 border border-[#C5C5E8] text-[#3F3DA5] mr-4 font-semibold text-sm px-4 py-2 rounded-full items-center justify-between"
                        }>
                            Result
                        </button>
                    </div>
                    { 
                        board === "attendance" ?
                            <StudentAttendance studentAttendance={studentAttendance} loading={loading} onAttendanceView={fetchAttendance} />
                        : board === "fees" ?
                            <StudentAddons />
                        :
                            <StudentResult school={school} studentId={studentId} classId={classId} />
                    }
                </div>
            </div>
        </div>
    );
}
export default StudentProfile;