import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Header, SkeletonTable, TableDropdown } from "../components";
import { useStateContext } from "../contexts/ContextProvider";
import { userRequest } from "../utils/requestMethods";
import { MdKeyboardArrowRight } from "react-icons/md";
import { ImSpinner } from "react-icons/im";
import { FiMoreHorizontal } from "react-icons/fi";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
    },
  },
};

const Mandates = ({ history }) => {
  const { user } = useStateContext();
  const [mandates, setMandates] = useState();
  //const [startDate, setStartDate] = useState(new Date());
  //const [endDate, setEndDate] = useState(new Date());
  const [clicked, setClicked] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [kycStatus, setKycStatus] = useState("");
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState(null);

  const fetchTransactions = useCallback(async () => {
    //console.log(kycStatus)
    try {
      setLoading(true);
      const { data } = await userRequest.get(`/schools?limit=500&kyc_status=${kycStatus}`);
      //console.log(data.schools);
      setMandates(data.schools);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [kycStatus]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const handleDropdownClick = (rowIndex, event) => {
    const rect = event.target.getBoundingClientRect();
    setDropdownIndex(dropdownIndex === rowIndex ? null : rowIndex);
    setDropdownPosition({
      top: rect.bottom + window.scrollY,
      left: rect.left + window.scrollX,
    });
  };

  const handleCloseDropdown = () => setDropdownIndex(null);

  const kycProgressMapping = {
    0: 'Not Started',
    1: 'Details Submitted',
    2: 'Reviewed',
    3: 'Anchor Account Created',
    4: 'Validation In Progress',
    5: 'Awaiting Docs',
    6: 'KYC Validation Failed',
    7: 'KYC Validated',
    8: 'Deposit Account Created'
  };

  const columns = [
    {
      name: "Customer ID",
      selector: (row) => row._id,
    },
    {
      name: "School Name",
      selector: (row) => row.school_name,
    },
    {
      name: "KYC Status",
      selector: (row) => <button style={row.kyc_status === "approved" ? { background: 'rgb(139, 231, 139)' } : row.kyc_status === "pending" ? { background: 'rgb(227, 160, 8)' } : { background: 'rgb(227, 79, 79)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-sm">{row.kyc_status === "approved" ? ("Approved") : row.kyc_status === "pending" ? ("Pending") : ("Not Started")}</button>,
    },
    {
      name: "KYC Progress",
      selector: (row) => kycProgressMapping[row.kyc_progress],
    },
    {
      cell: (row, rowIndex) => (
        <div className="relative">
          <button
            className="p-2 text-gray-600 hover:text-gray-900"
            onClick={(e) => handleDropdownClick(rowIndex, e)}
          >
            <FiMoreHorizontal className="text-2xl" />
          </button>
          <TableDropdown
            isVisible={dropdownIndex === rowIndex}
            position={dropdownPosition}
            options={[
              {
                label: "View Details",
                //icon: <img src={Icons.checkList} alt="Payout Icon" />,
                onClick: () => history.push(`/school/details/${row._id}`),
              },
              {
                label: "Classes Management",
                //icon: <img src={Icons.coupon04} alt="Payout Icon" />,
                onClick: () => history.push({ pathname: `/school/classes`, state: { school: row } }),
              },
              {
                label: "View Subjects",
                //icon: <img src={Icons.notebook} alt="Payout Icon" />,
                onClick: () => history.push(`/subjects/${row._id}`),
              },
              {
                label: "Edit Class Details",
                //icon: <img src={Icons.pencilEdit} alt="Payout Icon" />,
                onClick: () => history.push(`/update-class/${row._id}`),
              },
              /*{
                label: "Delete",
                icon: <img src={Icons.delete} alt="Payout Icon" />,
                onClick: () => deleteHandler(row._id),
                danger: true,
              },*/
            ]}
            onClose={handleCloseDropdown}
          />
        </div>
      ),
      width: "150px",
    },
  ];

  return (
    <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
      <button
        type="button"
        onClick={() => setClicked(!clicked)}
        className="mb-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
      >
        Filters
      </button>
      <Header category="Onboarding" title={"Welcome, " + user?.firstname + "!"} />
      {/*<h1 className="text-3xl font-extrabold tracking-tight text-slate-900 pl-2 pt-4 dark:text-gray-200">
        Welcome, {user?.firstname}!
      </h1>
      <p className="tracking-tight text-slate-600 pl-2 dark:text-gray-200">
        In-depth analysis of customers financial transactions
      </p>*/}
      <div className="py-4 overflow-scroll md:overflow-hidden">
        {clicked ? (
          <div className="grid gap-6 mb-6 md:grid-cols-4">
            <div>
              <label
                htmlFor="startDate"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                KYC Status
              </label>
              <select id="kycStatus" onChange={(e) => setKycStatus(e.target.value)} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <option value="">Select Status</option>
                <option value="approved">Approved</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
                <option value="not_started">Not Started</option>
              </select>
            </div>
            {/*<div>
              <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Name of School
              </label>
              <input
                type="text"
                id="kycStatus"
                onChange={(e) => setKycStatus(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="mt-6">
              <button
                type="submit"
                disabled={loading}
                // onClick={submitHandler}
                className="py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Search
              </button>
            </div>*/}
          </div>
        ) : null}
        {success &&
          <div className="alert alert-success d-flex align-items-center" role="alert">
            <i className="fal fa-check pr-10"></i>
            <div>
              Success! {success}
            </div>
          </div>
        }
        {error &&
          <div className="alert alert-warning d-flex align-items-center" role="alert">
            <i className="fal fa-lock pr-10"></i>
            <div>
              Error Occurred: {error}
            </div>
          </div>
        }
        <div className="border rounded-3xl">
          <DataTable
            data={mandates}
            columns={columns}
            getRowId={(row) => row._id}
            pagination
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<SkeletonTable rows={5} columns={6} />}
            highlightOnHover
            striped
          />
        </div>
      </div>
    </div>
  );
};

export default Mandates;
